@import url('https://fonts.googleapis.com/css2?family=Grand+Hotel&display=swap');

a{
  /* color: black !important; */
  /* text-decoration: inherit !important; */
}

.white-text {
  color: aliceblue !important;
}

.white-hover:hover {
  text-decoration: underline !important;
}

.blue-button {
  background: #5C74DA !important;
}

.card-content {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.input-white {
  color: aliceblue !important;
}

.mycard{
  margin-top: 30px;
}

.footer {
  width: inherit;
  height: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: medium;
  margin-left: 1rem;
}

.logo-image {
  width: 4rem;
  height: auto;
  object-fit: scale-down;
  margin-top: 4px;
  margin-bottom: 4px;
}

.icon-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1rem;
}

.simple-flex{
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-card{
  padding: 20px;
  text-align: center;
  /* max-width: 400px; */
  max-width: 50vw;
  margin: 10px auto;
}

.helpbox {
  margin: 10px auto;
  color: #cccccc;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.input-field input[type=text]:focus {
  border-bottom: 1px solid rgb(8, 93, 252) !important;
  box-shadow: 0 1px 0 0 rgb(8, 93, 252)  !important;
}

.gallery{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.item{
  width: 30%;
}

.collection-unit{
  width: 100%;
}

.iframe-card {
  max-width: 70vw;
  height: max-content;
  margin: 26px auto;
}

.iframe {
  width: 100%;
  height: 80vh;
}

.home-card{
  /* max-width: 500px; */
  max-width: 60vw;
  height: max-content;
  margin: 26px auto;
}

.material-icons:hover{
  cursor: pointer;
}

.hidden {
  display: none;
}